
export default class TextReplace {



  constructor($) {

    this.searchString = 'Space-IT';
    this.$ = $;

    var all = $( ".text:contains("+this.searchString+")" );
    var _this = this;

    all.each(function(index){
      _this.processText(this);
    });
  }

  processText(item) {
    var text = item.innerHTML;
    // color
    var color = this.$(item).css('color'); // rgb(51,51,51)
    var size = this.$(item).css('font-size');

    var startIndex = text.indexOf(this.searchString);
    var endIndex = startIndex + this.searchString.length;

    var textStart = text.substr(0, startIndex);
    var endText = text.substr(endIndex);

    var replaceText = '<span class="replaced"><svg viewBox="0 0 184 38"><use xlink:href="#spaceit-logo"></use></svg><span class="replace-text">'+this.searchString+'</span></span>';
    var fullText = textStart + replaceText + endText;

    item.innerHTML = fullText;
  }

}


