// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

window.jQuery = require('jquery');
var lightGallery = require('../lib/lightgallery/js/lightgallery');
var lightGalleryVid = require('../lib/lightgallery/js/lg-video');
//var lightGalleryAuto = require('../lib/lightgallery/js/lg-autoplay');

import TextReplace from './_textReplace';

jQuery(() => {
  // my TextReplace class
  new TextReplace(jQuery);

  // make lightgallery work
  jQuery('.lightgallery').lightGallery({

    download: false,

    youtubePlayerParams: {
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      controls: 0
    }
  });
});
